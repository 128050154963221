<ng-container *ngIf="design" [ngTemplateOutlet]="DESIGN_2"></ng-container>
<ng-template #DESIGN_2>
  <div
    class="donation-spec"
    fxLayout="column"
    [fxLayout.gt-sm]="onlySmall ? 'column' : 'row'"
    fxLayoutGap="0"
    [fxLayoutGap.gt-sm]="onlySmall ? '0px' : '30px'"
    fxLayoutGap.lt-md="20px"
    *ngIf="specification"
  >
    <div fxLayout="column" fxLayoutGap="0" fxLayoutGap.lt-md="20px">
      <app-donation-spec-item
        *ngIf="!onlySmall"
        (specChanged)="onUpdate($event)"
        [count]="specification.xlarge"
        [key]="'xlarge'"
        itemTitle="Extra Large item"
        subtitle="2+ person lift / special equipment"
        description="Appliances, entertainment centers, china cabinets, wardrobes, large slabs of counter top, sectionals (each piece counts separately), dining room tables for 8 or more people and other items that should be carried by more than 2 people or require special equipment."
        icon="xl"
        fxFlex.xs="50"
        [disabled]="specialIds.includes(charityId?.toString() || '')"
        [leadId]="leadId"
        [activity]="activity.DONATION_SPEC_XL"
        [editable]="true"
      >
      </app-donation-spec-item>
      <app-donation-spec-item
        *ngIf="!onlySmall"
        (specChanged)="onUpdate($event)"
        [count]="specification.large"
        [key]="'large'"
        itemTitle="Large item"
        subtitle="2-person lift"
        description="Dinner tables, couches, dressers, side boards, executive desks, tall book cases and other items that should be carried by 2 people."
        icon="lg"
        fxFlex.xs="50"
        [disabled]="specialIds.includes(charityId?.toString() || '')"
        [leadId]="leadId"
        [activity]="activity.DONATION_SPEC_LG"
        [editable]="true"
      >
      </app-donation-spec-item>
      <app-donation-spec-item
        *ngIf="!onlySmall"
        (specChanged)="onUpdate($event)"
        [count]="specification.medium"
        [key]="'medium'"
        itemTitle="Medium item"
        subtitle="1-person lift"
        description="Dining room and desk chairs, end tables, stools, small rugs and other light items that should be carried by one person."
        icon="md"
        fxFlex.xs="50"
        [leadId]="leadId"
        [activity]="activity.DONATION_SPEC_MD"
        [editable]="true"
      >
      </app-donation-spec-item>
      <app-donation-spec-item
        (specChanged)="onUpdate($event)"
        [count]="specification.small"
        [key]="'small'"
        [itemTitle]="windowSize === windowSizes.XS ? 'Bags/boxes of clothes / housewares': 'Bags/boxes of clothes/housewares'"
        [subtitle]="smallLabel"
        description="A box is any item or items in a cardboard or plastic container that one person can carry safely. A bag refers to any item or collection of items contained in a sack, satchel, or similar carrier that can be safely handled by a single person. Examples of items that could go in bags or boxes include but are not limited to linens, clothes, plates, bowls, glassware, silverware, cookware, small appliances, small house decorations, books, and shoes. To help us ensure the quality of your donation, please check the container for any breaks, cracks, or rips before the hauler arrives. If you have glassware or fragile items, feel free to wrap them, label the box, or let the hauler know during pickup."
        icon="sm"
        fxFlex.xs="50"
        [leadId]="leadId"
        [activity]="activity.DONATION_SPEC_SM"
        [min]="onlySmall ? 5 : 0"
        [editable]="onlySmall ? false : true"
      >
      </app-donation-spec-item>
    </div>
    <div fxLayout="column" fxLayoutGap="0" fxLayoutGap.lt-md="20px">
      <app-donation-spec-item
        (specChanged)="onUpdate($event)"
        [count]="specification.staircases"
        [key]="'staircases'"
        itemTitle="Staircases"
        subtitle="If no elevator"
        description="If your donations must be moved up or down stairs and no elevator is available, please select the number of staircases that must be traversed! Certain elevators may incur a separate fee."
        icon="staircases"
        fxFlex.xs="50"
        [leadId]="leadId"
        [activity]="activity.DONATION_SPEC_STAIR_CASES"
        [editable]="onlySmall ? false : true"
      >
      </app-donation-spec-item>
      <app-donation-spec-item
        *ngIf="!onlySmall"
        (specChanged)="onUpdate($event)"
        [count]="specification.disassembly"
        [key]="'disassembly'"
        itemTitle="Disassembly"
        subtitle="Requiring tooling / upcharge items"
        description="Please add this for each item that requires tools for disassembly in order to remove!"
        icon="disassembly"
        fxFlex.xs="50"
        [leadId]="leadId"
        [activity]="activity.DONATION_SPEC_DISASSEMBLY"
        [editable]="true"
      >
      </app-donation-spec-item>
      <div class="divider"></div>
      <div
        class="cost-estimate"
        fxLayout="column"
        fxLayoutAlign="center center"
      >
        <div
          class="estimate-line-items"
          fxLayout="column"
          fxLayoutAlign="start start"
          *ngIf="showPrice"
        >
          <p
            class="line-item"
            fxLayout="row"
            fxLayoutAlign="space-between"
            *ngIf="pricing.bookingFee"
          >
            <span>Estimated On-Site Charges:</span>
            <span class="line-cost">{{ totalCostEstimate | currency }}</span>
          </p>
          <p
            class="line-item"
            fxLayout="row"
            fxLayoutAlign="space-between"
            *ngIf="pricing.bookingFee"
          >
            <span>Booking Fee (Charged Today):</span>
            <span class="line-cost">{{
              pricing.bookingFee || 0 | currency
            }}</span>
          </p>
          <div
            class="divider"
            style="width: 100%"
            *ngIf="pricing.bookingFee"
          ></div>
          <p class="line-item" fxLayoutAlign="space-between">
            <span>Estimated Total Cost of Service:</span>
            <span class="line-cost">{{
              (totalCostEstimate || 0) + (pricing.bookingFee || 0) | currency
            }}</span>
          </p>
        </div>
        <div
          fxLayoutAlign="center center"
          [ngStyle]="{ 'margin-top.px': showPrice ? 0 : 20 }"
          [ngClass]="{ 'align-right': submitColorPrimary }"
        >
          <button
            id="step1Submission"
            class="primary-btn filled-btn"
            [ngClass]="{ 'warn-btn': !submitColorPrimary }"
            (click)="save()"
            type="submit"
          >
            {{ saveLabel }}
          </button>
        </div>
        <p
          class="estimate-info"
          fxLayout="row"
          fxLayoutAlign="center center"
          *ngIf="showPrice"
          (click)="showEstimateInfo()"
        >
          <span style="margin: 0" class="line-cost">
            <mat-icon class="info-btn">info_outline</mat-icon></span
          >
          <span class="line-cost">How We Calculate Cost Estimates</span>
        </p>
      </div>
    </div>
  </div>
</ng-template>
