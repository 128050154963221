<ng-container *ngIf="design" [ngTemplateOutlet]="DESIGN_2"></ng-container>
<ng-template #DESIGN_2>
  <app-page-header
    [charity]="charity"
    [territory]="organization"
    title="Reserve your donation pickup."
    subtitle="Total estimate to be finalized on site"
    [hasBack]="hasBack && !cardOnFile"
    (onBack)="back()"
  ></app-page-header>
  <div class="page-content" fxLayout="column" fxLayoutAlign="start start">
    <div
      class="payment-instructions step-content"
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutAlign.lt-md="start start"
      *ngIf="!expired"
      [ngStyle]="{
        'padding-top.px': isSmallWindowSize ? 20 : 0
      }"
    >
      <div
        class="payment-content"
        fxLayout="column"
        fxLayout.gt-sm="row"
        fxLayoutAlign="start start"
        fxLayoutAlign.lt-md="start center"
        fxLayoutGap.lt-md="10px"
        fxLayoutGap="30px"
      >
        <div
          fxLayout="column"
          fxFlex="100"
          fxFlex.gt-sm="60"
          style="width: 100%"
        >
          <div class="order-preview">
            <h2 style="margin-top: 0">Order preview</h2>
            <div
              style="margin-bottom: 15px"
              fxLayout="row"
              fxLayoutAlign="space-between center"
            >
              <div fxLayout="column">
                <span><b>Pickup date & time</b></span>
                <span
                  >{{ lead.date | date }} -
                  {{ lead.partOfDay?.toUpperCase() }}</span
                >
              </div>
              <button
                class="primary-btn"
                [routerLink]="['/', 'place-time', flowType, flowId]"
              >
                Change
              </button>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <div fxLayout="column">
                <span><b>Pickup address</b></span>
                <span>{{ lead.address.fullAddress }}</span>
              </div>
              <button
                class="primary-btn"
                [routerLink]="['/', 'place-time', flowType, flowId]"
              >
                Change
              </button>
            </div>
          </div>
          <div
            fxLayout="row"
            fxLayoutAlign="end"
            style="width: 100%"
            *ngIf="lead?.discount?.valid"
          >
            <div
              class="discount-badge"
              fxLayout="row"
              fxLayoutAlign="space-between center"
              fxLayoutGap="10px"
            >
              <div fxLayout="column">
                <span
                  ><b
                    >You got {{ sponsorshipPercent }}%
                    Off</b
                  ></span
                >
                <span>Sponsored by</span>
              </div>
              <img src="{{ charity?.logo }}" alt="{{ charity?.name }}" />
            </div>
          </div>
          <div fxLayout="row" fxFlex="100">
            <div
              fxLayout="column"
              fxLayoutAlign="start center"
              fxFlex="100"
              *ngIf="lead"
            >
              <button
                *ngIf="!showQuoteDetails && estimate"
                class="primary-btn filled-btn quote-details-btn btn-block"
                (click)="showQuoteDetails = true"
                [ngClass]="{
                  hidden: !isSmallWindowSize,
                  discounted: lead?.discount?.valid
                }"
              >
                Show Cost Breakdown:
                {{
                  (((estimate.donationPrice || 0) - sponsoredAmount)) + (estimate.bookingFee || 0) | currency
                }}
              </button>
              <div
                *ngIf="!showQuoteDetails && isSmallWindowSize"
                class="estimated-pickup-cost"
                fxFlexAlign="space-between"
              >
                <span style="margin-bottom: 4px; line-height: 14px"
                  >Estimated Pickup Cost <br />(Due On-Site)
                  <img
                    (click)="showDiscountInfoDialog()"
                    *ngIf="lead?.discount?.valid"
                    class="discount-info-ic"
                    src="/assets/images/info.svg"
                  />
                </span>
                <div fxLayout="row">
                  <span>{{ donationPrice - sponsoredAmount | currency }}</span>
                  <span *ngIf="lead?.discount?.valid" class="discounted-value">
                    {{ donationPrice | currency }}
                  </span>
                </div>
              </div>
              <div
                *ngIf="!showQuoteDetails && isSmallWindowSize"
                class="todays-charges"
                fxFlexAlign="space-between"
              >
                <span>Today's Charges</span>
                <span>{{ lead.pricing.bookingFee || 0 | currency }}</span>
              </div>

              <!--  ********************************** desktop LEFT section start ********************************  -->

              <div
                fxLayout="column"
                fxLayout.gt-xs="row"
                fxFlex="100"
                [ngClass]="{
                  hidden: isSmallWindowSize ? !showQuoteDetails : false,
                  discount: lead?.discount?.valid
                }"
              >
                <rspl-donation-estimate
                  fxFlex="100"
                  *ngIf="lead && pricing && lead.specification"
                  [pricing]="pricing"
                  [specification]="lead.specification"
                  [showZeros]="false"
                  [editable]="charity?.meta?.onlySmallItems ? false : true"
                  [showUnitPrices]="false"
                  [bookingFee]="lead.pricing.bookingFee"
                  [showTodaysCharges]="!lead?.discount?.valid"
                  [showDiscount]="lead?.discount?.valid"
                  [discountedBy]="charity?.logo"
                  [leadOrDonation]="lead"
                  (onSpecificationChange)="onSpecificationChange()"
                  totalLabel="Estimated Pickup Cost (Due On-Site)"
                  bookingLabel="Booking Fee (Due Today)"
                  [isCalculating]="isCalculatingPrice"
                >
                </rspl-donation-estimate>
              </div>
            </div>
          </div>
        </div>
        <!--  ********************************** desktop RIGHT section start ********************************  -->
        <div
          fxLayout="column"
          fxLayoutGap="15px"
          fxFlex="100"
          fxFlex.gt-sm="40"
          style="width: 100%"
        >
          <div
            fxLayout="row"
            fxLayoutAlign="space-between"
            *ngIf="!isSmallWindowSize && lead?.discount?.valid"
          >
            <span class="booking-fee-label">Today's charges</span>
            <span class="booking-fee-label">{{
              lead.pricing.bookingFee || 0 | currency
            }}</span>
          </div>
          <ng-container [ngTemplateOutlet]="Checkbox"></ng-container>
          <div class="stripe-wrapper">
            <ng-container [ngTemplateOutlet]="STRIPE"></ng-container>
            <div
              fxLayoutAlign="center center"
              fxLayout="column"
              *ngIf="isLoaded"
            >
              <button
                id="step5Submission"
                class="submit-button primary-page-btn success-btn btn-block"
                id="submitDonationButton"
                (click)="saveLead()"
                [ladda]="isSubmitting"
                [disabled]="!isLoaded"
              >
                Reserve Your Pickup
              </button>
              <div
                fxLayout="row"
                fxLayoutAlign="center center"
                class="powered-by"
              >
                <mat-icon class="lock-icon">lock</mat-icon>
                <span>Secure payments through</span>
                <mat-icon class="stripe-icon" svgIcon="stripe"></mat-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="error-wrapper" fxLayoutAlign="center center">
      <p class="error" *ngIf="error && !expired">{{ error }}</p>
      <p class="error expired" *ngIf="expired">
        Your link has expired, please contact support to get a new link
      </p>
    </div>

    <div
      fxLayout="column"
      fxLayout.gt-sm="row"
      class="progress-wrapper"
      *ngIf="!cardOnFile"
    >
      <app-create-progress
        fxFlex
        [step]="flowStep"
        [flowConfiguration]="flowConfiguration"
      ></app-create-progress>
    </div>
  </div>
  <ng-template #Checkbox>
    <div fxLayout="column" class="checkboxes checkbox-container">
      <div
        class="form-row form-row-checkbox"
        fxLayout="row"
        fxLayoutAlign="start start"
        fxFlex.gt-sm="50"
        fxPosition="static"
      >
        <input
          type="checkbox"
          id="userTerms"
          name="userTerms"
          [(ngModel)]="userTerms"
        />
        <button
          class="checkbox"
          (click)="onCheckboxChange($event)"
          [ngClass]="{ invalid: !userTerms && showErrors }"
        >
          <mat-icon svgIcon="checkmark" *ngIf="userTerms"></mat-icon>
        </button>
        <label
          [ngClass]="{ invalid: !userTerms && showErrors }"
          for="userTerms"
        >
          <ng-container *ngIf="(lead.pricing.bookingFee || 0) > 0">
            <b
              >I understand my card is only being charged a
              {{ lead.pricing.bookingFee || 0 | currency }} reservation fee at
              this time.</b
            >
          </ng-container>
          <ng-container *ngIf="(lead.pricing.bookingFee || 0) === 0">
            <b>I understand my card will not be charged at this time.</b>
          </ng-container>
          The final cost of the donation pickup will be charged once my
          donations have been delivered to the
          {{ (charity || lead?.charity)?.name || 'charity' }}.
          <ng-container *ngIf="(lead.pricing.bookingFee || 0) > 0">
            If I cancel my donation, the
            {{ lead.pricing.bookingFee || 0 | currency }} reservation fee is not
            refundable. If ReSupply is unable to complete my donation pickup,
            the reservation fee can be refunded by contacting ReSupply support.
          </ng-container>
          I agree to
          <a
            href="https://resupplyme.com/utility/donor-priority-pickups-terms-of-use"
            target="_blank"
            >ReSupply's Terms and Conditions</a
          >.
        </label>
        <p
          *ngIf="!userTerms && showErrors"
          class="invalid terms-validation-error"
          fxLayout="row"
          fxLayoutGap="2px"
          fxLayoutAlign="center center"
          fxPosition="absolute"
        >
          <mat-icon>info_outline</mat-icon>
          <span>Check this box to agree to the Terms & Conditions</span>
        </p>
      </div>
    </div>
  </ng-template>
</ng-template>
<ng-template #STRIPE>
  <rspl-stripe
    *ngIf="
      estimate &&
      flowService.paymentIntent &&
      flowService.paymentIntent.client_secret
    "
    [paymentIntent]="flowService.paymentIntent"
    (onReady)="cardReady()"
    [type]="!!flowService.paymentIntent.booking_fee ? 'payment' : 'setup'"
    (onSuccess)="paymentSuccess()"
    (onError)="onPaymentError($event)"
    [total]="{
      amount:
        (flowService.paymentIntent.booking_fee
          ? estimate.bookingFee || 0
          : estimate.donationPrice || 0) * 100,
      label: 'Total Cost'
    }"
    [newCard]="true"
    [disableButtons]="!userTerms"
    (onDisabledButtonsClicked)="disabledButtonsClicked()"
    (onPaymentSetupSuccess)="onPaymentSetupSuccess($event)"
  ></rspl-stripe>
</ng-template>
