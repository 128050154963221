import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ButtonActivity,
  ENVIRONMENT,
  Environment,
  Lead,
  PageActivity,
  Specification,
} from '@domains';
import { AppService } from '@donor/app.service';
import { CategorizeHelpDialogComponent } from '@donor/components/categorize-help-dialog/categorize-help-dialog.component';
import { InfoDialogComponent } from '@donor/components/info-dialog/info-dialog.component';
import { OptimizelyService } from '@donor/optimizely.service';
import { FlowService } from '@donor/screens/flow/donor-flow.service';
import { BaseFlowStepComponent } from '@donor/screens/flow/steps/base-flow-step.component';
import { DesignService, ResponsiveService } from '@rspl-ui';
import { take } from 'rxjs';

@Component({
  selector: 'app-flow-donation-details',
  templateUrl: './flow-donation-details.component.html',
  styleUrls: ['./flow-donation-details.component.scss'],
})
export class FlowDonationDetailsComponent
  extends BaseFlowStepComponent
  implements OnInit
{
  specification: Specification;
  activity = ButtonActivity;

  constructor(
    override flowService: FlowService,
    override appService: AppService,
    override router: Router,
    override route: ActivatedRoute,
    override designService: DesignService,
    override responsiveService: ResponsiveService,
    protected dialog: MatDialog,
    protected override optimizelyService: OptimizelyService,
    @Inject(ENVIRONMENT) protected override environment: Environment
  ) {
    super(
      flowService,
      appService,
      router,
      route,
      designService,
      responsiveService,
      optimizelyService,
      environment
    );
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.specification = new Specification(this.flowService.lead.specification);
    if (this.specification?.isEmpty()) {
      if (this.route.snapshot.queryParams['disassembly'] === 'true') {
        this.specification.disassembly = 1;
      } else {
        this.specification.disassembly = 0;
      }
      if (this.route.snapshot.queryParams['stairs'] === 'true') {
        this.specification.staircases = 1;
      } else {
        this.specification.staircases = 0;
      }
    }
  }

  static override isCompleted(lead: Lead) {
    return !lead.specification.isEmpty();
  }

  override isValid(): boolean {
    return !this.specification.isEmpty();
  }

  override getFromValue(): Partial<Lead> {
    return {
      specification: this.specification,
    };
  }

  override showError(): void {
    this.appService
      .createLeadActivity(
        this.lead?.id,
        ButtonActivity.DONATION_SPEC_SUBMIT_ERROR,
        undefined,
      )
      .pipe(take(1))
      .subscribe();
    this.dialog.open(InfoDialogComponent, {
      data: {
        title: 'No Items',
        message:
          'You need to select at least one item to donate before proceeding to the next step',
        button: 'I understand.',
        icon: 'warning',
      },
    });
  }

  override saveButtonActivity(): ButtonActivity {
    return ButtonActivity.DONATION_SPEC_SUBMIT;
  }

  override pageVisitActivity(): PageActivity {
    return PageActivity.DONATION_SPEC_PAGE;
  }

  onSpecificationChange(e: any): void {
    Object.assign(this.specification || {}, e);
  }

  categorizeHelp() {
    this.dialog.open(CategorizeHelpDialogComponent, {
      ...(this.isSmallWindowSize
        ? {
            width: '900px',
            maxWidth: '90vw',
            height: '85vh',
            maxHeight: '85vh',
          }
        : {}),
      ...{
        panelClass: 'categorize-help-dialog',
        data: {
          charity: this.charity,
          small: {
            title:
            'Bags/boxes of clothes/housewares',
            description:
              'A box is any item or items in a cardboard or plastic container that one person can carry safely. A bag refers to any item or collection of items contained in a sack, satchel, or similar carrier that can be safely handled by a single person. Examples of items that could go in bags or boxes include but are not limited to linens, clothes, plates, bowls, glassware, silverware, cookware, small appliances, small house decorations, books, and shoes. To help us ensure the quality of your donation, please check the container for any breaks, cracks, or rips before the hauler arrives. If you have glassware or fragile items, feel free to wrap them, label the box, or let the hauler know during pickup.',
          },
        },
      },
    });
  }

  get onlySmall(): boolean {
    return !!this.charity?.meta?.onlySmallItems;
  }

  get specialIds(): string[] {
    return this.environment.special.onlySmall;
  }
}
