import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ButtonActivity,
  Donation, DonationPartnerState,
  DonationStateByCodeDTO,
  PageActivity,
  PartOfDay,
  Specification
} from '@domains';
import { AppService } from '@donor/app.service';
import { DonationsService } from '@rspl-api';
import { finalize, take } from 'rxjs/operators';

@Component({
  selector: 'app-created',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {
  donationCode: string;
  donation!: Donation | any;
  expired = false;
  isSubmittingDonorInformation = false;
  isSubmittingDonationDate = false;
  isSubmittingDonationSpec = false;
  donorInformationSaved = false;
  donationDateChanged = false;
  donationSpecChanged = false;
  selectedDate: {
    date?: string;
    partOfDay?: PartOfDay;
  } = {};

  public specification: Specification = new Specification();
  public totalCostEstimate?: number;
  partnerStates = DonationPartnerState;

  public form!: FormGroup<{
    donor: FormGroup<{
      name: FormControl<string>;
      email: FormControl<string>;
      phone: FormControl<string>;
    }>;
    instructions: FormControl<string | null>;
    donationItems: FormControl<string | null>;
  }>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service: AppService,
    private donationsService: DonationsService,
  ) {
    this.donationCode = this.route.snapshot.params['code'];
  }

  ngOnInit(): void {
    this.getDonation();
  }

  getDonation(): void {
    this.service.getDonationByCode(this.donationCode).pipe(take(1))
      .subscribe({
        next: donation => {
          this.donation = donation;
          if (donation) {
            this.selectedDate = {
              date: donation.date,
              partOfDay: donation.partOfDay,
            };
            this.onSpecificationChange(this.donation?.specification);
            this.initForm();
            this.service.createDonationActivity(this.donation?.id, PageActivity.EDIT_DONATION_PAGE).subscribe();
          }
        },
        error: () => {
          this.router.navigate(['/', 'page-not-found'], {
            queryParams: {
              message: `Donation <b>${this.donationCode}</b> could not be found!`,
              url: window.location.href
            }
          });
        }
      });
  }

  private initForm(): void {
    this.form = new FormGroup({
      donor: new FormGroup({
        name: new FormControl(this.donation?.donor?.name || '', [
          Validators.required,
          Validators.pattern(/^[^\s]+.*$/)
        ]),
        email: new FormControl(this.donation?.donor?.email || '', [
          Validators.required,
          Validators.pattern(
            /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
          )
        ]),
        phone: new FormControl(this.donation?.donor?.phone || '', [
          Validators.required,
          Validators.pattern(/^([0-9]{10})$/)
        ])
      }),
      instructions: new FormControl<string | null>(this.donation?.instructions || null),
      donationItems: new FormControl<string | null>(this.donation?.donationItems || null)
    });
  }

  onDateSelected(e: { date?: string; partOfDay?: PartOfDay }): void {
    this.selectedDate = e;
  }

  onSpecificationChange(e: any): void {
    Object.assign(this.specification || {}, e);
    this.specification = new Specification({ ...this.specification });
  }

  saveDonorInformation(): void {
    this.service
      .createDonationActivity(
        this.donation?.id,
        ButtonActivity.EDIT_DONOR_INFO_SUBMIT
      )
      .subscribe();

    if (this.form.valid) {
      this.isSubmittingDonorInformation = true;
      this.service
        .updateDonationByCode(
          new Donation({
            ...(this.donation as Donation),
            ...this.form.getRawValue()
          })
        )
        .pipe(
          finalize(() => (this.isSubmittingDonorInformation = false)),
          take(1)
        )
        .subscribe({
          next: (donation) => {
            this.donation = donation;
            console.log(donation);
            this.donorInformationSaved = true;
          },
          error: () => {
            this.service
              .createDonationActivity(
                this.donation?.id,
                ButtonActivity.EDIT_DONOR_INFO_SUBMIT_ERROR
              )
              .subscribe();
          }
        });
    }

  }

  saveDonationDate(): void {
    if ((this.selectedDate.date === this.donation.date) && (this.selectedDate.partOfDay === this.donation.partOfDay)) {
      return;
    }

    this.service
      .createDonationActivity(
        this.donation?.id,
        ButtonActivity.RESCHEDULE_DONATION_SUBMIT
      )
      .subscribe();
    this.isSubmittingDonationDate = true;
    if (this.selectedDate?.date && this.selectedDate?.partOfDay) {
      this.donationsService
        .updateDonationStateByCode(
          this.donationCode,
          new DonationStateByCodeDTO({
            stateAction: 'reschedule',
            date: this.selectedDate.date,
            partOfDay: this.selectedDate.partOfDay,
          })
        )
        .pipe(take(1))
        .subscribe({
          next: () => {
            this.donationDateChanged = true;
            this.isSubmittingDonationDate = false;
            this.donation.date = this.selectedDate.date;
            this.donation.partOfDay = this.selectedDate.partOfDay;
          },
          error: () => {
            this.isSubmittingDonationDate = false;
            this.service
              .createDonationActivity(
                this.donation?.id,
                ButtonActivity.RESCHEDULE_DONATION_SUBMIT_ERROR
              )
              .subscribe();
          },
        });
    }
  }

  saveDonationSpec(): void {
    this.service
      .createDonationActivity(
        this.donation?.id,
        ButtonActivity.EDIT_DONATION_SPEC_SUBMIT
      )
      .subscribe();
    this.isSubmittingDonationSpec = true;
    this.service
      .updateDonationByCode(
        new Donation({
          ...(this.donation as Donation),
          specification: this.specification,
        })
      )
      .pipe(
        finalize(() => (this.isSubmittingDonationSpec = false)),
        take(1)
      )
      .subscribe({
        next: (donation) => {
          this.donation = donation;
          this.donationSpecChanged = true;
        },
        error: () => {
          this.service
            .createDonationActivity(
              this.donation?.id,
              ButtonActivity.EDIT_DONATION_SPEC_SUBMIT_ERROR
            )
            .subscribe();
        },
      });
  }


}
